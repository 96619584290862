import React from 'react';
import { graphql } from 'gatsby';
import map from 'lodash/map';
import { ARTISTS_SEO } from '../utils/seo';

import Layout from 'components/layout';
import { Artists } from 'components/Artists';

import { GetArtistsIndexQuery } from 'types';

interface IArtistsIndexPageProps {
  data: GetArtistsIndexQuery;
  location: Location;
}

export const ArtistsPage: React.FC<IArtistsIndexPageProps> = ({
  data: { artists, latestArtists },
}) => {
  const latestArtistNames = map(
    latestArtists.edges,
    ({ node: { name } }) => name
  ).join(', ');

  return (
    <Layout
      title={ARTISTS_SEO.title}
      description={ARTISTS_SEO.description(latestArtistNames)}
      pathname={ARTISTS_SEO.pathname}
    >
      <Artists artists={artists} />
    </Layout>
  );
};

export default ArtistsPage;

export const artistsIndexQuery = graphql`
  query GetArtistsIndex {
    artists: allContentfulArtist(sort: { fields: [name], order: ASC }) {
      edges {
        node {
          slug
          name
          images {
            fluid(maxWidth: 375) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }

    latestArtists: allContentfulArtist(
      sort: { fields: [createdAt], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          name
        }
      }
    }
  }
`;
