import React from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import { ARTIST_DETAILS_ROUTE } from 'utils/routes';
import { GetArtistsIndexQuery } from 'types';

import PageHeader from 'components/common/PageHeader';
import { ArtistListItem } from 'components/Artists/ArtistListItem';

import styles from './Artists.module.css';

interface IArtistsIndexProps {
  artists: GetArtistsIndexQuery['artists'];
}

export const Artists: React.FC<IArtistsIndexProps> = ({ artists }) => {
  return (
    <>
      <PageHeader title="Artists" />
      <section className={styles.artistsContainer}>
        <div className={styles.artists}>
          {map(artists.edges, ({ node: { name, slug, images = [] } }) => (
            <ArtistListItem
              key={slug}
              title={name}
              to={ARTIST_DETAILS_ROUTE(slug)}
              backgroundImage={get(images[0], 'fluid', '')}
            />
          ))}
        </div>
      </section>
    </>
  );
};
